<template>
  <div class="project-general">
    <p class="project-general-heading">{{ type }}</p>

    <div class="project-general-body">
      <div class="project-general-body-top">
        <div class="project-general-body-left">
          <div class="project-general-title-container">
            <IconEducation icon-color="#17A2B8" />
            <h4 class="green" style="padding-left: 5px">
              Feasibility #{{ project.project_number }}
            </h4>
          </div>
          <div class="project-general-title-container">
            <IconEducation icon-color="#17A2B8" />
            <h4 class="green" style="padding-left: 5px">
              Project Number #{{ project.feasibility_number }}
            </h4>
          </div>
        </div>

        <button class="icon-button">
          <IconPrinter
            icon-color="#17A2B8"
            width="30"
            height="30"
            @click="printDocument"
          />
        </button>
      </div>

      <hr class="dashed" style="margin-top: 0" />

      <div class="project-general-body-bottom">
        <div class="project-general-body-data">
          <h3 v-if="project.description_short" class="green center">
            {{ project.description_short }}
          </h3>

          <div v-if="project.categories.length">
            <span class="green" style="padding-right: 5px">
              <b> Pathology:</b>
            </span>
            <span v-for="category in project.categories" :key="category">
              {{ category.title }} /
            </span>
          </div>

          <div v-if="project.timeline">
            <span class="green" style="padding-right: 5px">
              <b> Timeline:</b>
            </span>
            <span>{{ project.timeline }}</span>
          </div>

          <div v-if="project.donors_number">
            <span class="green" style="padding-right: 5px">
              <b> #Donors:</b>
            </span>
            <span>{{ project.donors_number }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="project-general-footer">
      <p class="project-general-footer-text">General Information</p>
    </div>
  </div>
  <PrintedDocument :categories="project.categories" />
</template>

<script>
import PrintElem from "@/modules/helpers/printDocument.js";
import PrintedDocument from "@/components/print/PrintDocument.vue";

import IconPrinter from "@/modules/components/icons/IconPrinter";
import IconEducation from "@/modules/components/icons/IconEducation";

export default {
  components: {
    PrintedDocument,
    IconPrinter,
    IconEducation,
  },
  props: {
    project: {
      type: Object,
    },
    type: {
      type: String,
      default: "Project",
    },
  },
  setup() {
    const printDocument = () => PrintElem("print");

    return { printDocument };
  },
};
</script>

<style lang="scss" scoped>
.project {
  &-general {
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: var(--col-bg-primary);
    overflow: hidden;

    &-heading {
      margin-bottom: 5px;
      padding: 7px 30px;
      border-radius: 4px;
      font-weight: bold;
      background-color: var(--col-info);
      text-align: center;
      color: #fff;
    }

    &-body {
      padding: 0 30px;

      &-bottom {
        padding: 10px 0;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .icon-button {
        padding: 10px 20px;
        border: none;
        border-left: 1px dashed var(--col-primary-light);
        background-color: transparent;
      }
    }

    &-title-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-data {
      flex: 1;
    }

    &-footer {
      padding: 3px;
      background-color: var(--col-info);
      text-align: center;

      &-text {
        font-size: 16px;
        font-weight: bold;
        color: var(--col-contrast-text);
      }
    }
  }
}
</style>
