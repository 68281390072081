<template>
  <div class="sidebar">
    <!-- <div v-if="categories" class="field-container">
      <h3 class="green">Pathology:</h3>
      <div class="green-border">
        <span
          v-for="categorie in categories"
          class="categorie"
          :key="categorie"
        >
          {{ categorie.title }}
          <span class="slash">/</span>
        </span>
      </div>
    </div> -->

    <div v-if="project.public_note" class="field-container">
      <h3 class="green">Public note:</h3>
      <div class="green-border">
        {{ project.public_note }}
      </div>

      <hr class="dashed" style="margin-bottom: 10px" />
    </div>

    <div v-if="project && project.files && project.files.length">
      <div class="file-field-container_bordered">
        <div
          v-for="(file, index) in project.files"
          class="file-field-container"
          :key="index"
        >
          <div class="file-field-bordered">
            <a :href="file.file_url" target="_blank">
              <div class="file-field-body">
                <div style="width: 31px">
                  <component
                    :is="`Icon${file.extension}`"
                    icon-color="#17a2b8"
                  />
                </div>
                <span class="file-field-name"
                  >{{ file.original_file_name }}
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>

      <hr class="dashed" />
    </div>

    <div class="sidebar-admin-data">
      <h6 class="green center">Published by:</h6>
      <AdminSign :createdBy="project.created_by"/>
    </div>
  </div>

  <div
    v-if="
      project &&
      project.id &&
      !project.is_archived &&
      type !== 'Archive Project'
    "
    class="sidebar"
  >
    <ChatDialog :id="project.id" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import AdminSign from "@/components/base/AdminSign.vue";
import ChatDialog from "@/components/chat/ChatDialog.vue";
import Icondoc from "@/modules/components/icons/filesTypes/IconDoc.vue";
import IconNone from "@/modules/components/icons/filesTypes/IconNone.vue";
import Iconpdf from "@/modules/components/icons/filesTypes/IconPdf.vue";
import Iconpng from "@/modules/components/icons/filesTypes/IconPng.vue";
import Icontxt from "@/modules/components/icons/filesTypes/IconTxt.vue";
import Iconzip from "@/modules/components/icons/filesTypes/IconZip.vue";

export default {
  components: {
    AdminSign,
    ChatDialog,
    Icondoc,
    IconNone,
    Iconpdf,
    Iconpng,
    Icontxt,
    Iconzip,
  },
  props: {
    categories: {
      type: Array,
      default: function () {
        return [];
      },
    },
    type: {
      default: "active",
    },
  },
  setup() {
    const store = useStore();
    const project = computed(() => store.getters["projects/project"]);

    return { project };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  min-width: 230px;

  &-admin-data {
    max-width: 180px;
    margin: 0 auto;
    padding-bottom: 10px;
  }
}

.categories {
  text-align: center;
}

.categorie {
  &:last-child {
    .slash {
      display: none;
    }
  }
}
</style>
