<template>
  <div v-if="project.donor_characteristics">
    <h3 class="green">Donor Characteristics</h3>
    <pre class="">
            {{ project.donor_characteristics }}
          </pre
    >
  </div>

  <div v-if="project.inclusion_criteria">
    <h3 class="green">Inclusion Criteria</h3>
    <pre class="">{{ project.inclusion_criteria }}</pre>
  </div>

  <div v-if="project.exclusion_criteria">
    <h3 class="green">Exclusion Criteria</h3>
    <pre class="">{{ project.exclusion_criteria }}</pre>
  </div>

  <div v-if="project.preservation">
    <h3 class="green">Preservation / Procurement</h3>
    <pre class="">{{ project.preservation }}</pre>
  </div>

  <div v-if="project.clinical_info">
    <h3 class="green">Clinical Information</h3>
    <pre class="">{{ project.clinical_info }}</pre>
  </div>

  <div v-if="project.additional_fields && project.additional_fields.length">
    <h3 class="grey">Additional Fields:</h3>
    <div v-for="(field, ind) in project.additional_fields" :key="ind">
      <h3 class="green" style="margin-bottom: 0">
        {{ field.title }}
      </h3>
      <pre class="">{{ field.value }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    setup() {},
  },
};
</script>
