<template>
  <div id="print" class="document-wrapper">
    <div v-if="!isPrintable && project && Object.keys(project).length">
      <div class="document">
        <div class="document-head spaced">
          <div class="document-head-title">
            <div>
              <h2 class="red" style="margin: 0; padding: 0">CURELINE</h2>
              <p>Project management</p>
            </div>

            <div class="document-head-title-left">
              <p class="grey">Project number:</p>
              <p class="green">#{{ project.feasibility_number }}</p>
              <p class="grey" style="margin: 0; padding: 10px 0 0 0">
                Feasibility number:
              </p>
              <b class="grey">#{{ project.project_number }}</b>
            </div>
          </div>
        </div>

        <div class="document-body">
          <h3
            v-if="project.description_short"
            class="document-body-heading spaced green"
          >
            {{ project.description_short }}
          </h3>

          <div class="document-body-main">
            <div>
              <div class="spaced">
                <div class="document-body-main-project">
                  <p v-if="project.indication">
                    <span class="green bold">Indication: </span>
                    <span class="grey"> {{ project.indication }}</span>
                  </p>
                  <p v-if="project.donors_number">
                    <span class="green bold">Donors: </span>
                    <span class="grey"> {{ project.donors_number }}</span>
                  </p>
                  <p v-if="project.timeline">
                    <span class="green bold">Timeline: </span>
                    <span class="grey"> {{ project.timeline }}</span>
                  </p>
                  <p v-if="user.role == 'admin' && project.price">
                    <span class="green bold">Price: </span>
                    <span class="grey"> {{ project.price }}</span>
                  </p>
                </div>
              </div>

              <div
                v-if="categories && categories.length"
                class="spaced bordered"
              >
                <p>Pathology:</p>
                <span
                  v-for="(category, ind) in categories"
                  class="grey"
                  :key="ind"
                >
                  {{ category.title
                  }}<span v-if="ind + 1 < categories.length">, </span>
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="project.lines && project.lines.length"
            class="document-body-section spaced"
          >
            <h3 class="green" style="margin: 0 10px; padding: 0 10px">
              Indications:
            </h3>
            <div
              v-for="(line, l) in project.lines"
              class="document-body-line spaced"
              :key="l"
            >
              <p><b>Title: </b> {{ line.title }}</p>
              <p><b>Description: </b> {{ line.description }}</p>
              <p><b>Cases: </b> {{ line.cases_count }}</p>
            </div>

            <!-- <hr class="dashed" /> -->
          </div>

          <div class="document-body-section">
            <div v-if="project.public_note" class="spaced">
              <p class="bold green">Public note:</p>
              <p class="grey">{{ project.public_note }}</p>
            </div>

            <div v-if="project.private_note" class="spaced">
              <p class="bold green">Private note:</p>
              <p class="grey">{{ project.private_note }}</p>
            </div>

            <div v-if="project.donor_characteristics" class="spaced">
              <p class="bold green">Donor Characteristics:</p>
              <p class="grey">{{ project.donor_characteristics }}</p>
            </div>

            <div v-if="project.inclusion_criteria" class="spaced">
              <p class="bold green">Inclusion Criteria:</p>
              <p class="grey">{{ project.inclusion_criteria }}</p>
            </div>

            <div v-if="project.exclusion_criteria" class="spaced">
              <p class="bold green">Exclusion Criteria:</p>
              <p class="grey">{{ project.exclusion_criteria }}</p>
            </div>

            <div v-if="project.preservation" class="spaced">
              <p class="bold green">Preservation/Procurement:</p>
              <p class="grey">{{ project.preservation }}</p>
            </div>

            <div v-if="project.clinical_info" class="spaced">
              <p class="bold green">Clinical information:</p>
              <p class="grey">{{ project.clinical_info }}</p>
            </div>
          </div>
        </div>

        <div class="document-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    categories: {
      default: [],
    },
  },
  setup() {
    const store = useStore();

    const isPrintable = ref(false);
    const project = computed(() => store.getters["projects/project"]);
    let user = computed(() => store.getters["auth/user"]);

    return { isPrintable, project, user };
  },
};
</script>

<style lang="scss" scoped>
#print {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: 0;
  transform: translate(-10000px, 1000px);
}
@media print {
  p {
    padding: 0;
    margin: 0;
  }
  .document {
    padding: 20px 5px 5px 30px;
  }
  .document-wrapper {
    background-color: #fff;
  }
  .document-head {
    margin-bottom: 10px;
  }
  .document-head-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .document-head-title-left {
    text-align: right;
  }
  .document-head-client {
    > p {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      > b {
        display: block;
        min-width: 100px;
        padding-right: 10px;
      }
    }
  }
  .document-body-heading {
    padding: 10px;
    text-align: center;
  }
  .document-body-main {
    display: flex;
    border-bottom: 1px dashed #ced4da;
    margin-bottom: 10px;
    > div {
      flex: 1;
      border-top: 1px dashed #ced4da;
      border-right: 1px dashed #ced4da;
      &:last-child {
        border-right-width: 0;
      }
    }
  }
  .document-body-main-project {
    > p {
      padding-right: 20px;
    }
  }
  .document-body-section {
    margin-bottom: 10px;
  }
  .document-body-line {
    border-bottom: 1px dashed #ced4da;
  }
  .bold {
    font-weight: bold;
  }
  .spaced {
    padding: 10px 20px;
  }
  .bordered {
    border-top: 1px dashed #ced4da;
  }
  .green {
    color: #17a2b8;
  }
  .grey {
    color: #6c757d;
  }
  .red {
    color: #dc3545;
  }
}
</style>
